import axios from 'lib/axios'
import { useState } from 'react'

export default function CategoriesRequests() {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const Get = async ({ setCategories }) => {
        try {
            setIsLoading(true)

            await csrf()

            const response = await axios.get(
                '/api/v1/forAdmin/getAllCategories',
            )
            const data = await response.data
            setCategories(data.data)

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const Delete = async ({ id }) => {
        try {
            setIsLoading(true)
            setIsError(false)

            await csrf()

            const { data } = await axios.delete(
                `/api/v1/forAdmin/deleteCategory/${id}`,
            )

            if (data.error) {
                setIsError(true)
            }

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            setIsError(true)
        }
    }

    const Update = async ({ Data }) => {
        try {
            setIsLoading(true)
            setIsError(false)

            await csrf()

            const res = await axios.put(
                `/api/v1/forAdmin/updateCategory/${Data.id}`,
                Data,
            )
            const data = await res.data

            if (data.error) {
                setIsError(true)
            }

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            setIsError(true)
        }
    }

    const Create = async ({ Data, categories, setCategories }) => {
        try {
            setIsLoading(true)
            setIsError(false)

            await csrf()

            const res = await axios.post(
                '/api/v1/forAdmin/createCategory',
                Data,
            )
            const data = await res.data

            if (data.error) {
                setIsError(true)
            }

            // push data to array.
            setCategories([data.data, ...categories])

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            setIsError(true)
        }
    }

    return {
        isLoading,
        isError,
        Get,
        Delete,
        Update,
        Create,
    }
}
